import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Plugins } from "@capacitor/core";
const { App: CapApp } = Plugins;

const AppUrlListener: React.FC<any> = () => {
  let history = useHistory();
  useEffect(() => {
    CapApp.addListener("appUrlOpen", (data: any) => {
      // Example url: https://app.volunteerio.us/opps/{id}
      // slug = /tabs/tab2
      const slug = data.url.split(".app").pop();
      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
    // eslint-disable-next-line
  }, []);

  return null;
};

export default AppUrlListener;
